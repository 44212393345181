import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Typography, Modal, IconButton } from "@material-ui/core";

import FeedbackModalWrapper from "./styles/FeedbackModal.style";
import Analytics from "helpers/analytics";
import CloseIcon from "../../images/close.svg";
import DownThumb from "../../images/down-thumb.png";
import UpThumb from "../../images/up-thumb.png";
import DownThumbHover from "../../images/down-thumb-hover.svg";
import UpThumbHover from "../../images/up-thumb-hover.svg";
import FeedbackIcon from "../../images/feedback-icon.png";
import Sparkle from "../../images/sparkle.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 340,
  height: 267,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  padding: "20px 20px 32px 20px"
};

export default function FeedbackModal({ openModal }) {
  const dispatch = useDispatch();
  const [feedbackGiven, setFeedbackGiven] = React.useState(false);
  const [hoverState, setHoverState] = React.useState({ up: false, down: false });

  const searchTerms = useSelector((state) => state.pages.search.searchTerms);
  const search = useSelector((state) => state.pages.search);

  const handleClose = () => {
    if (!feedbackGiven) {
      Analytics.track("search_feedback_popup", {
        category: "LP_search",
        label: "search_feedback",
        feedback: "close_popup",
        search_type: searchTerms === "" ? "null search" : "keyword search",
        last_used_search_query: searchTerms,
        total_number_of_search_results: search?.pagination?.totalResults
      });
    }
    dispatch({ type: "HIDE_FEEDBACK_MODAL" });
  };

  const handleFeedback = (feedback) => {
    setFeedbackGiven(true);
    Analytics.track("search_feedback_popup", {
      category: "LP_search",
      label: "search_feedback",
      feedback: feedback ? "thumbs_up" : "thumbs_down",
      search_type: searchTerms === "" ? "null search" : "keyword search",
      last_used_search_query: searchTerms,
      total_number_of_search_results: search?.pagination?.totalResults
    });
  };

  return (
    <Modal
      open={openModal}
      onClose={!feedbackGiven ? handleClose : undefined}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <FeedbackModalWrapper>
        <Box sx={style}>
          {!feedbackGiven && (
            <>
              <div className="close-icon" onClick={handleClose}>
                <img src={CloseIcon} alt="close icon" />
              </div>

              <img className="feedback-icon" src={FeedbackIcon} alt="feedback icon" />
              <h2 className="modal-title-question" id="modal-title">
                Did you find what you were looking for?
              </h2>
              <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
                {/* Thumbs Up Button */}
                <IconButton
                  onClick={() => handleFeedback(true)}
                  onMouseEnter={() => setHoverState({ ...hoverState, up: true })}
                  onMouseLeave={() => setHoverState({ ...hoverState, up: false })}
                  color="primary"
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={hoverState.up ? UpThumbHover : UpThumb}
                    alt="up thumb"
                    className="thumb-icon"
                  />
                </IconButton>

                {/* Thumbs Down Button */}
                <IconButton
                  onClick={() => handleFeedback(false)}
                  onMouseEnter={() => setHoverState({ ...hoverState, down: true })}
                  onMouseLeave={() => setHoverState({ ...hoverState, down: false })}
                  color="secondary"
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={hoverState.down ? DownThumbHover : DownThumb}
                    alt="down thumb"
                    className="thumb-icon"
                  />
                </IconButton>
              </Box>
              <div className="privacy-policy">
                <p>
                  Your feedback is anonymous and used to improve our search experience. See our{" "}
                  <a
                    className="privacy-policy-link"
                    target="blanck"
                    href="https://www.jukinmedia.com/licensing/privacy-policy"
                  >
                    privacy policy
                  </a>{" "}
                  for details.
                </p>
              </div>
            </>
          )}

          {feedbackGiven && (
            <>
              <img src={Sparkle} alt="sparcle icon" className="sparkle-icon" />
              <h2 id="modal-description" className="thanks-feedback">
                Thank you for your feedback.
              </h2>
              <button onClick={handleClose} variant="contained" className="feedback-button">
                Close
              </button>
            </>
          )}
        </Box>
      </FeedbackModalWrapper>
    </Modal>
  );
}

export const addUrlToSession = (routerLocation) => {
  const sessionUrlsKey = "lastVisitedUrls";
  const sessionUrls = sessionStorage.getItem(sessionUrlsKey);
  if (sessionUrls) {
    const lastVisitedUrls = [routerLocation, ...JSON.parse(sessionUrls)];
    sessionStorage.setItem(sessionUrlsKey, JSON.stringify(lastVisitedUrls));
  } else {
    const lastVisitedUrls = [routerLocation];
    sessionStorage.setItem(sessionUrlsKey, JSON.stringify(lastVisitedUrls));
  }
};

export const getSessionUrls = () => {
  const sessionUrlsKey = "lastVisitedUrls";
  const sessionUrls = sessionStorage.getItem(sessionUrlsKey);
  if (sessionUrls) {
    return JSON.parse(sessionUrls);
  }
  return false;
};

export const isPrerequisiteCriteria = () => {
  const sessionUrls = getSessionUrls();
  if (sessionUrls) {
    const urlDatas = {
      searches: [],
      pages: [],
      videos: []
    };
    sessionUrls.forEach((urlLocation) => {
      const search = urlLocation.search;
      const path = urlLocation.pathname;
      if (urlLocation.search) {
        const searchParams = new URLSearchParams(search);
        const query = searchParams.get("q");
        const page = searchParams.get("page") ? parseInt(searchParams.get("page")) : 0;

        if (query && page) {
          //User searched for a term
          if (page == 1) {
            if (!urlDatas.searches.includes(search)) {
              urlDatas.searches.push(search);
            }
          }
          //User went to a page > 1
          if (page > 1) {
            if (!urlDatas.pages.includes(search)) {
              urlDatas.pages.push(search);
            }
          }
        }
      }
      //User landed on a video detail page
      if (path.includes("/view")) {
        if (!urlDatas.videos.includes(path)) {
          urlDatas.videos.push(path);
        }
      }
    });

    const searchTermsCount = urlDatas.searches.length;
    const pageViewsCount = urlDatas.pages.length;
    const videosCount = urlDatas.videos.length;
    const totalEvents = searchTermsCount + pageViewsCount + videosCount;

    console.log(
      `Total Events: ${totalEvents}, Terms searched: ${searchTermsCount}, Page views: ${pageViewsCount}, Videos landed: ${videosCount}`
    );

    if (searchTermsCount >= 3 || pageViewsCount >= 4 || videosCount >= 3 || totalEvents >= 4) {
      return true;
    }
  }
  return false;
};

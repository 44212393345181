import {
  CART,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CREATED_SAVED_LISTS_FROM_STORAGE,
  CURRENT_VIDEO_LIST,
  FAVORITES,
  HEADER_SAVED_LISTS,
  INQUIRY,
  LISTS_FOR_VIDEO,
  LOADING,
  LOADING_LIST_VIDEOS,
  LOADING_POPUP_LISTS,
  LOADING_HEADER_LISTS,
  LOADING_SAVED_LISTS,
  TOGGLE_LISTS_POPUP,
  TOGGLE_LISTS_MULTIPLE_VIDEOS_POPUP,
  UPDATE_LIST_FOR_VIDEO,
  VIDEO_LISTS,
  CLEAR_SEARCH,
  LOGIN_MODAL,
  ADD_TO_CART_MODAL,
  DIGITAL_TERMS_MODAL,
  BROADCAST_TERMS_MODAL,
  CREATE_SAVED_VIDEO_FROM_CART_MODAL,
  UPDATE_LISTS_COUNT,
  UPDATE_CART,
  SHOW_FEEDBACK_MODAL,
  HIDE_FEEDBACK_MODAL
} from "../actions/user";

import { actions } from "../actions/shopify";
const { SHOPIFY_CHECKOUT_COMPLETED } = actions;

const initialState = {
  searchParams: {
    categories: [],
    clearanceStatus: [],
    display: "desc",
    footageType: [],
    openFilters: true,
    page: 1,
    perPage: 20,
    q: null,
    sort: "signedDate",
    tone: []
  },
  favsPerPage: 20,
  favPage: 0,
  uuid: "",
  loginModalIsOpen: false,
  addToCartModalIsOpen: false,
  createSavedVideoFromCartModalIsOpen: false,
  cartItems: [],
  shopifyReady: false,
  itemSubmitting: false,
  listsCount: 0,
  digitalTermsModalIsOpen: false,
  feedbackModal: {
    isOpen: false,
    lastShown: null,
    sessionShown: false
  }
};

export default function user(state = initialState, action) {
  const setState = (obj) => ({ ...state, ...obj }); //Object.assign({}, state, obj)
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return setState({
        inquirySuccess: false
      });
    case CLEAR_SEARCH:
      return setState({ searchParams: initialState.searchParams });

    case FAVORITES:
      return setState(action.data);

    case VIDEO_LISTS:
      return setState(action.data);

    case LOADING:
      return setState({
        loading: true
      });

    case SHOW_FEEDBACK_MODAL:
      return setState({
        feedbackModal: {
          isOpen: true,
          lastShown: state.feedbackModal.lastShown,
          sessionShown: true
        }
      });

    case HIDE_FEEDBACK_MODAL:
      return setState({
        feedbackModal: {
          ...state.feedbackModal,
          isOpen: false
        }
      });

    case TOGGLE_LISTS_POPUP:
    case TOGGLE_LISTS_MULTIPLE_VIDEOS_POPUP:
    case LISTS_FOR_VIDEO:
    case LOADING_POPUP_LISTS:
    case LOADING_HEADER_LISTS:
    case LOADING_SAVED_LISTS:
    case LOGIN_MODAL:
    case ADD_TO_CART_MODAL:
    case DIGITAL_TERMS_MODAL:
    case BROADCAST_TERMS_MODAL:
      return setState(action.data);

    case UPDATE_LIST_FOR_VIDEO:
      let actionList = null;
      let lists = state.popupLists
        .filter((list) => {
          if (list.uuid === action.data.uuid) {
            const skipCountUpdating = action.data.disabled;
            const newCount =
              list.videoCount + (skipCountUpdating ? 0 : action.data.containsVideo ? 1 : -1);
            actionList = {
              ...list,
              ...{
                disabled: action.data.disabled,
                containsVideo: action.data.containsVideo,
                videoCount: action.data.multipleVideosCounter
                  ? action.data.multipleVideosCounter
                  : newCount,
                changed: action.data.changed,
                disableCheckBox: action.data.containsVideo ? true : false
              }
            };
          }
          return !action.data.updateOrder || list.uuid !== action.data.uuid;
        })
        .map((list) => {
          const skipCountUpdating = action.data.disabled || !action.data.updateOrder;
          const newCount =
            list.videoCount + (skipCountUpdating ? 0 : action.data.containsVideo ? 1 : -1);
          if (list.uuid === action.data.uuid) {
            return {
              ...list,
              ...{
                disabled: action.data.disabled,
                containsVideo: action.data.containsVideo,
                videoCount: action.data.multipleVideosCounter
                  ? action.data.multipleVideosCounter
                  : newCount,
                changed: action.data.changed,
                disableCheckBox: action.data.containsVideo ? true : false
              }
            };
          }
          return list;
        });
      const videoLists = action.data.updateOrder ? [actionList].concat(lists) : lists;
      return setState({ popupLists: videoLists });

    case LOADING_LIST_VIDEOS:
      return setState({
        loadingListVideos: action.data ? action.data.loadingListVideos : true
      });

    case CURRENT_VIDEO_LIST:
      return setState(action.data);

    case CART:
    case ADD_TO_CART:
      return setState({
        loading: false,
        cartItems: action.data,
        itemSubmitting: true
      });
    case REMOVE_FROM_CART:
      return setState({
        loading: false,
        cartItems: action.data,
        itemSubmitting: false
      });
    case INQUIRY:
      return setState({
        inquirySuccess: true,
        shopifyReady: false
      });

    case CREATE_SAVED_VIDEO_FROM_CART_MODAL:
      return setState({
        createSavedVideoFromCartModalIsOpen: action.data.createSavedVideoFromCartModalIsOpen
      });

    case CREATED_SAVED_LISTS_FROM_STORAGE:
      return setState({
        cartItems: [],
        loading: false
      });

    case HEADER_SAVED_LISTS:
      return setState(action.data);

    case SHOPIFY_CHECKOUT_COMPLETED:
      return setState({
        cartItems: [],
        shopifyReady: false
      });
    case UPDATE_LISTS_COUNT:
      return setState({ listsCount: action.payload });
    case UPDATE_CART:
      return setState({ cartItems: action.data });
    default:
      return state;
  }
}

export const internalDomains = [
  "tmbi.com",
  "trustedmediabrands.com",
  "tmb.com",
  "readersdigest.com",
  "besthealthmag.ca",
  "birdsandblooms.com",
  "buildconstructpros.com",
  "failarmy.com",
  "familyhandyman.com",
  "jukinmedia.com",
  "jukinproductions.com",
  "ourcanada.ca",
  "rd.ca",
  "rd.com",
  "rdigest.com",
  "readersdigest.ca"
];

import request from "superagent";
import { Mixpanel } from "components/shared/Mixpanel";
import Analytics from "helpers/analytics";
import { getProfile } from "httpRequests/auth";
import isPopupAllowed from "helpers/isPopupAllowed";
import { isPrerequisiteCriteria } from "helpers/sessionUrls";

const authActions = {
  AUTH_PENDING_UPDATE: "AUTH_PENDING_UPDATE",
  LOGIN_FULFILLED: "LOGIN_FULFILLED",
  LOGIN_REJECTED: "LOGIN_REJECTED",
  LOGOUT_FULFILLED: "LOGOUT_FULFILLED",
  LOGOUT_REJECTED: "LOGOUT_REJECTED",
  VALIDATE_FULFILLED: "VALIDATE_FULFILLED",
  VALIDATE_REJECTED: "VALIDATE_REJECTED",
  REGISTER_FULFILLED: "REGISTER_FULFILLED",
  REGISTER_REJECTED: "REGISTER_REJECTED",
  CLEAR_AUTH: "CLEAR_AUTH",
  GET_USER_PROFILE: "GET_USER_PROFILE",
  RESET_ERROR: "RESET_ERROR",
  SET_LOGGING_OUT: "SET_LOGGING_OUT",

  registerRequest: (registerData) => {
    localStorage.setItem("tp", registerData.password);
    return (dispatch) => {
      dispatch(authActions.authPendingUpdate(true));

      return request
        .post(`${process.env.REACT_APP_RIFF_URL}/api/public/register`)
        .set("Content-Type", "application/json")
        .send(registerData)
        .then(async (res) => {
          if (res.ok) {
            const prevPath = localStorage.getItem("prevPath")
              ? localStorage.getItem("prevPath")
              : "/licensing";
            localStorage.removeItem("prevPath");
            window.location = prevPath;
            dispatch({ type: "JMLP/UPDATE_LISTS_COUNT", payload: 0 });
            dispatch(authActions.registerFulFilled(res.body));

            getProfile().then(async (response) => {
              await dispatch(authActions.getUserProfile(response.body));
            });
          } else {
            dispatch(authActions.registerRejected(null));
          }
          dispatch(authActions.authPendingUpdate(false));
          return true;
        })
        .catch((err) => {
          if (err.status === 429) {
            dispatch(
              authActions.registerRejected([
                "Too many registration attempt. Please try again later."
              ])
            );
          } else if (err.status === 400) {
            let errMessage;
            if (err.response.body.response) {
              errMessage = JSON.parse(err.response.body.response.text).messages;
            } else {
              errMessage = err.response.body.messages;
            }
            dispatch(authActions.registerRejected(errMessage));
          }

          dispatch(authActions.authPendingUpdate(false));
          return false;
        });
    };
  },

  loginRequest: (authData) => {
    return (dispatch, getState) => {
      dispatch(authActions.authPendingUpdate(true));

      request
        .post("/api/login")
        .set("Content-Type", "application/json")
        .send(authData)
        .then(async (res) => {
          if (res.ok) {
            const selectNonRMItem = getState().user.cartItems.filter((item) => !item.playlistUUID);
            await dispatch({ type: "JMLP/ADD_TO_CART", data: selectNonRMItem });
            await dispatch({ type: "JMLP/UPDATE_LISTS_COUNT", payload: 0 });
            await dispatch(authActions.loginFulFilled(res.body));

            Mixpanel.track("Sign In Successfully");
            localStorage.setItem("token", res.body.access_token);

            getProfile().then(async (response) => {
              await dispatch(authActions.getUserProfile(response.body));
            });
          } else {
            dispatch(authActions.loginRejected());
          }
          dispatch(authActions.authPendingUpdate(false));
        })
        .catch(() => {
          dispatch(authActions.loginRejected());
          dispatch(authActions.authPendingUpdate(false));
        });
    };
  },

  logoutRequest: () => {
    return (dispatch, getState) => {
      const token = getState().auth.user.access_token;
      dispatch(authActions.authPendingUpdate(true));
      dispatch({ type: authActions.SET_LOGGING_OUT, payload: true });
      request
        .post("/api/logout")
        .set("Authorization", `Bearer ${token}`)
        .then((res) => {
          if (res.ok) {
            dispatch({ type: authActions.SET_LOGGING_OUT, payload: false });
            dispatch(authActions.logoutFulFilled());
            Analytics.reset();
            localStorage.removeItem("token");
            const { cartItems } = getState().user;
            const selectNonRMItem = cartItems.filter((item) => !item.playlistUUID);
            dispatch({ type: "JMLP/ADD_TO_CART", data: selectNonRMItem });
            if (isPrerequisiteCriteria() && isPopupAllowed()) {
              dispatch({ type: "SHOW_FEEDBACK_MODAL" });
            }
          } else {
            dispatch(authActions.logoutRejected());
          }
          dispatch(authActions.authPendingUpdate(false));
        })
        .catch(() => {
          dispatch(authActions.logoutRejected());
          dispatch(authActions.authPendingUpdate(false));
        });
    };
  },

  validateTokenRequest: (token) => {
    return (dispatch) => {
      dispatch(authActions.authPendingUpdate(true));

      request
        .post("/api/validate")
        .set("Authorization", `Bearer ${token}`)
        .then(async (res) => {
          if (res.ok) {
            await dispatch(authActions.validateFulFilled(res.body));
            await dispatch(authActions.loginFulFilled(res.body));
            localStorage.setItem("token", token);
            getProfile().then(async (response) => {
              await dispatch(authActions.getUserProfile(response.body));
            });
          } else {
            dispatch(authActions.tokenExpired());
            localStorage.removeItem("token");
          }
        })
        .catch(() => {
          dispatch(authActions.tokenExpired());
          localStorage.removeItem("token");
        });
    };
  },

  tokenExpired: () => {
    return (dispatch, getState) => {
      dispatch(authActions.validateRejected());

      if (getState().auth.loggedIn) {
        dispatch(authActions.logoutRequest());
      }
    };
  },

  getUserProfile: (profile) => ({
    type: authActions.GET_USER_PROFILE,
    payload: {
      profile: profile
    }
  }),

  clearAuth: () => ({
    type: authActions.CLEAR_AUTH
  }),

  authPendingUpdate: (pending) => ({
    type: "AUTH_PENDING_UPDATE",
    payload: {
      pending
    }
  }),

  loginFulFilled: (session) => ({
    type: authActions.LOGIN_FULFILLED,
    payload: {
      user_session: session
    }
  }),

  loginRejected: () => ({
    type: authActions.LOGIN_REJECTED
  }),

  logoutFulFilled: () => ({
    type: authActions.LOGOUT_FULFILLED
  }),

  logoutRejected: () => ({
    type: authActions.LOGOUT_REJECTED
  }),

  validateFulFilled: (session) => ({
    type: authActions.VALIDATE_FULFILLED,
    payload: {
      user_session: session
    }
  }),

  validateRejected: () => ({
    type: authActions.VALIDATE_REJECTED
  }),

  registerFulFilled: (userData) => ({
    type: authActions.REGISTER_FULFILLED,
    payload: {
      userData
    }
  }),

  registerRejected: (err) => ({
    type: authActions.REGISTER_REJECTED,
    payload: {
      error: err
    }
  }),

  resetErrors: (err) => ({
    type: authActions.RESET_ERROR
  })
};

export default authActions;

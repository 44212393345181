import request from "superagent";
import { inquiryEmail } from "../../helpers/sendEmail";
import forUser from "../../helpers/forUserQueryHelper";
import authActions from "./auth";
import { videoDetailDates, videoBoxDate } from "../../helpers/convertDate";
import { setSnackbar } from "../actions/snackbar";
import fetchPlaylist from "./playlist/services/fetchPlaylist";

export const CLEAR_SEARCH = "JMLP/CLEAR_SEARCH";
export const FAVORITES = "FAVORITES";
export const CART = "CART";
export const ADD_TO_CART = "JMLP/ADD_TO_CART";
export const REMOVE_FROM_CART = "JMLP/REMOVE_FROM_CART";
export const UPDATE_CART = "JMLP/UPDATE_CART";
export const INQUIRY = "JMLP/INQUIRY";
export const LOADING = "JMLP/LOADING";
export const VIDEO_LISTS = "VIDEO_LISTS";

export const LOADING_POPUP_LISTS = "LOADING_POPUP_LISTS";
export const TOGGLE_LISTS_POPUP = "TOGGLE_LISTS_POPUP";
export const TOGGLE_LISTS_MULTIPLE_VIDEOS_POPUP = "TOGGLE_LISTS_MULTIPLE_VIDEOS_POPUP";
export const LISTS_FOR_VIDEO = "LISTS_FOR_VIDEO";
export const UPDATE_LIST_FOR_VIDEO = "UPDATE_LIST_FOR_VIDEO";
export const LIST_CREATED = "LIST_CREATED";

// saved video lists
export const DOWNLOAD_PLAYLIST = "JMLP/DOWNLOAD_PLAYLIST";
export const HEADER_SAVED_LISTS = "HEADER_SAVED_LISTS";
export const LOADING_HEADER_LISTS = "LOADING_HEADER_LISTS";
export const LOADING_SAVED_LISTS = "LOADING_SAVED_LISTS";
export const LOADING_LIST_VIDEOS = "LOADING_LIST_VIDEOS";
export const CURRENT_VIDEO_LIST = "CURRENT_VIDEO_LIST";
export const LOGIN_MODAL = "LOGIN_MODAL";
export const ADD_TO_CART_MODAL = "ADD_TO_CART_MODAL";
export const DIGITAL_TERMS_MODAL = "JMLP/DIGITAL_TERMS_MODAL";
export const BROADCAST_TERMS_MODAL = "JMLP/BROADCAST_TERMS_MODAL";
export const CREATE_SAVED_VIDEO_FROM_CART_MODAL = "JMLP/CREATE_SAVED_VIDEO_FROM_CART_MODAL";
export const CREATED_SAVED_LISTS_FROM_STORAGE = "JMLP/CREATED_SAVED_LIST_FROM_STORAGE";
export const UPDATE_LISTS_COUNT = "JMLP/UPDATE_LISTS_COUNT";

export const SHOW_FEEDBACK_MODAL = "SHOW_FEEDBACK_MODAL";
export const HIDE_FEEDBACK_MODAL = "HIDE_FEEDBACK_MODAL";

const { tokenExpired } = authActions;
let token = null;

let cartData = [];
let listId = window.location.pathname.split("/")[4];

function getToken(state) {
  return state.auth.user.access_token;
}

function getCurrentCartItems(state) {
  return state.user.cartItems;
}

function changeShopifyState(state) {
  return (state.user.shopifyReady = false);
}

if (localStorage.getItem("persist:root") !== null) {
  let currentState = JSON.parse(localStorage.getItem("persist:root"));
  let auth = JSON.parse(currentState.auth);
  token = auth.user.access_token ? auth.user.access_token : null;
}

export function clearAllSearch() {
  return (dispatch) => {
    dispatch({ type: CLEAR_SEARCH });
  };
}

function itemAdd(data) {
  return {
    type: ADD_TO_CART,
    data: data
  };
}

function itemRemove(data) {
  return {
    type: REMOVE_FROM_CART,
    data: data
  };
}

function itemUpdate(data) {
  return {
    type: UPDATE_CART,
    data: data
  };
}

/**
 * get cart items
 * @param  function dispatch [dispatch to reducers]
 * @return function calls    [add cart items to the users saved items and dispatch to reducers]
 */
function getCartItems(dispatch) {
  dispatch({ type: LOADING });
}

/**
 * add to cart
 * @param  {string} jmId            [id of video]
 * @param  {string} publishingTitle [title of video]
 * @param  {string} imgUrl          [image of video]
 * @param  {string} variantId       [Shopify product Variant Id]
 * @param  {string} price           [Shopify product price]
 * @return {function} dispatch      [dispartch new cart list to reducers]
 */
export function addToCart(
  jmId,
  publishingTitle,
  imgUrl,
  variantId,
  price,
  productName,
  type,
  showSnackbarView = false
) {
  return (dispatch, getState) => {
    let currentItem = {
      variantId: variantId,
      quantity: 1,
      id: jmId,
      title: publishingTitle,
      imgUrl: imgUrl,
      price: price,
      productName: productName,
      uId: jmId + variantId,
      type
    };
    cartData = getCurrentCartItems(getState());
    cartData.push(currentItem);
    dispatch(itemAdd(cartData));
    dispatch(
      setSnackbar({
        open: true,
        message: type === "Gift Card" ? "Discount Pack added to cart" : "Video added to cart",
        severity: "default",
        showCartView: showSnackbarView
      })
    );
  };
}

export function updateLineItem(index, value, type) {
  return (dispatch, getState) => {
    cartData = getCurrentCartItems(getState());
    if (type === "video") {
      cartData[index].contentUsage = value;
    } else {
      cartData[index].quantity = value;
    }
    dispatch(itemUpdate(cartData));
  };
}

/**
 * remove from cart
 * @param  {string}   jmId            [id of video]
 * @param  {string}   publishingTitle [title of video]
 * @param  {string}   imgUrl          [image of video]
 * @return {function} dispatch        [dispartch new cart list to reducers]
 */
export function removeFromCart(jmId, publishingTitle, imgUrl) {
  return (dispatch, getState) => {
    cartData = getCurrentCartItems(getState());
    cartData.map((item, index) => {
      if (item.id === jmId) {
        cartData.splice(index, 1);
      }
      return true;
    });
    if (cartData.length === 0) {
      changeShopifyState(getState());
    }
    dispatch(itemRemove(cartData));
  };
}

/**
 * get user info
 * @param  {object} user            [user initial state]
 * @param {boolean} loadLists       [load saved list flag]
 * @return {function} getCartItems  [calls the function to get cart items]
 * runs when main component is loaded
 */
export function getUserInfo(auth, loadLists = false) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING });
    if (!auth.user.access_token) {
      return getCartItems(dispatch);
    } else {
      getCartItems(dispatch);
      loadHeaderVideoListsInternal(dispatch, getToken(getState()), 1);
    }
  };
}

/**
 * new inquiry
 * @param  {object} inquiry  [inquiry object with user info and videos]
 * @return {function} dispatch  [update reducer with successful inquiry]
 * runs on checkout page when 'place inquiry' button is clicked
 */
export function newInquiry(inquiry) {
  return (dispatch) => {
    inquiryEmail(inquiry);
    dispatch({
      type: INQUIRY
    });
  };
}

export function clearSFLead() {
  return (dispatch) => {
    localStorage.removeItem("SFLead");
    dispatch({
      type: INQUIRY
    });
  };
}

export function newLead(inquiry) {
  return (dispatch) => {
    var form = document.createElement("form");
    form.method = "POST";
    form.action = process.env.REACT_APP_SALESFORCE_LEAD_URL;
    // eslint-disable-next-line
    JSON.parse(inquiry).map((item) => {
      var theInput = document.createElement("input");
      theInput.name = item.name;
      theInput.setAttribute("value", item.value);
      if (item.name === "oid" || item.name === "retURL") {
        theInput.setAttribute("type", "hidden");
      } else {
        theInput.setAttribute("type", "text");
      }
      form.appendChild(theInput);
    });

    document.body.appendChild(form);
    form.submit();
    localStorage.setItem("SFLead", true);
  };
}

export function toggleDigitalTermsModal(isOpen) {
  return (dispatch) => {
    dispatch({
      type: DIGITAL_TERMS_MODAL,
      data: {
        digitalTermsModalIsOpen: isOpen
      }
    });
  };
}

export function toggleBroadcastTermsModal(isOpen) {
  return (dispatch) => {
    dispatch({
      type: BROADCAST_TERMS_MODAL,
      data: {
        broadcastTermsModalIsOpen: isOpen
      }
    });
  };
}

/**
 * Open/hide AddToListPopup for video
 * @param {string} jmId         [video jmId]
 * @param {string} token        [user token]
 * @return {function} dispatch  [dispatch selected video and popup isOpen to reducers]
 */
export function toggleModal(jmId, token) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_LISTS_POPUP,
      data: {
        selectedVideo: jmId,
        listsModalIsOpen: jmId !== null
      }
    });
  };
}

/**
 * Open/hide AddToListModalMultipleVideos modal
 * @param {array} jmIds         [video jmIds]
 * @return {function} dispatch  [dispatch selected video and popup isOpen to reducers]
 */
export function toggleMultipleVideosModal(jmIds) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_LISTS_MULTIPLE_VIDEOS_POPUP,
      data: {
        selectedVideos: jmIds,
        listsModalMultipleVideosIsOpen: jmIds?.length > 0
      }
    });
  };
}

/**
 * video list for AddToListPopup
 * @param  {object} responseData [object returned from api]
 * @return {object}              [object taken by redux and handled by the reducer]
 */
function videoListsByVideo(responseData) {
  return {
    type: LISTS_FOR_VIDEO,
    data: {
      popupLists: responseData,
      loadingPopupLists: false
    }
  };
}

/**
 * Update specific list and sort lists if needed
 * @param {string} listUUID      [Video list item]
 * @param {boolean} containsVideo [video list's containsVideo flag]
 * @param {boolean} disabled     [flag for disabling/enabling video adding/removing]
 * @param {boolean} updateOrder  [should list be sorted]
 * @return {object}              [object taken by redux and handled by the reducer]
 */
function updateListItem(
  listUUID,
  containsVideo,
  disabled = false,
  updateOrder = false,
  changed = null,
  multipleVideosCounter = false
) {
  return {
    type: UPDATE_LIST_FOR_VIDEO,
    data: {
      uuid: listUUID,
      containsVideo: containsVideo,
      disabled: disabled,
      updateOrder: updateOrder,
      changed: changed,
      multipleVideosCounter
    }
  };
}

/**
 * Load lists for specific video
 * @param {string} jmId         [video jmId]
 * @param {function} success    [success callback]
 * @param {function} failure    [failure callback]
 * @return {function} dispatch  [dispatch lists for video to reducers]
 */
export function getListsByVideo(jmId, success = null, failure = null, multipleVideosInPlaylist) {
  return (dispatch, getState) => {
    dispatch({ type: LOADING_POPUP_LISTS, data: { loadingPopupLists: true } });
    request
      .get(`/api/user/licensing/lists/video/${jmId}${forUser()}`)
      .set("Authorization", `Bearer ${getToken(getState())}`)
      .then((res, err) => {
        if (res.status === 401) {
          tokenExpired();
        }

        if (!res.ok) {
          console.log(err);
          failure && failure();
        } else {
          if (multipleVideosInPlaylist) {
            res.body = res.body.map((video) => {
              video.containsVideo = false;
              video.disableCheckBox = false;
              return video;
            });
          }
          dispatch(videoListsByVideo(res.body));
          success && success();
        }
      })
      .catch(() => {
        dispatch({
          type: LOADING_POPUP_LISTS,
          data: { loadingPopupLists: false }
        });
        failure && failure();
      });
  };
}

/**
 * remove video from specific list
 * @param {string} uuid         [video list uuid]
 * @param {string} videoJmId    [video jmId]
 * @param {function} refreshCallback    [function to call on request success]
 * @param {function} errorCallback      [function to call on request error]
 */
function addVideoToListInternal(uuid, videoJmId, token, refreshCallback, errorCallback) {
  request
    .post(`/api/user/licensing/lists/${uuid}/videos/${videoJmId}${forUser()}`)
    .set("Authorization", `Bearer ${token}`)
    .then((res, err) => {
      if (!res.ok) {
        errorCallback && errorCallback("Saving Error!");
        console.log(err);
      } else {
        refreshCallback && refreshCallback();
      }
    })
    .catch((err) => {
      if (err.response && err.response.body && err.response.body.messages.length > 0) {
        err.response.body.messages.forEach((message) => {
          if (message.startsWith("Max size")) {
            errorCallback &&
              errorCallback(
                "Yikes! Your playlist is full. Let’s remove a video or add to a different playlist."
              );
          } else {
            errorCallback && errorCallback("Saving Error!");
          }
        });
      } else {
        errorCallback && errorCallback("Saving Error!");
      }
    });
}

/**
 * add multiple videos from specific list
 * @param {string} uuid         [video list uuid]
 * @param {array} videoJmIds    [video jmId]
 * @param {function} refreshCallback    [function to call on request success]
 * @param {function} errorCallback      [function to call on request error]
 */
function addVideosToListInternal(uuid, videoJmIds, token, refreshCallback, errorCallback) {
  request
    .post(`/api/user/licensing/lists/${uuid}/videos`)
    .set("Authorization", `Bearer ${token}`)
    .send({
      jmIds: videoJmIds
    })
    .then((res, err) => {
      if (!res.ok) {
        errorCallback && errorCallback("Error adding videos to playlist!");
        console.log(err);
      } else {
        request
          .get(`/api/public/user/licensing/lists/${uuid}/videos?offset=0`)
          .set("Authorization", `Bearer ${token}`)
          .then((res) => {
            const totalVideos = res.body.total;
            refreshCallback && refreshCallback(totalVideos);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    })
    .catch((err) => {
      if (err?.response && err?.response?.text) {
        const errorMessage = JSON.parse(err?.response?.text).messages[0];
        if (errorMessage) {
          errorCallback && errorCallback(errorMessage);
        } else {
          errorCallback && errorCallback("Error ading videos to playlist!");
        }
      }
    });
}

/**
 * Add video to specific list
 * @param {string} uuid             [video list item]
 * @param {string} jmId             [video jmId]
 * @param {function} success        [success callback]
 * @param {function} failure        [failure callback]
 * @return {function} dispatch      [dispatch updated list item to reducers]
 */
export function addVideoToList(uuid, jmId, token, success, failure) {
  const containsVideo = false;
  return (dispatch) => {
    dispatch(updateListItem(uuid, containsVideo, true));
    return addVideoToListInternal(
      uuid,
      jmId,
      token,
      () => {
        dispatch(
          updateListItem(uuid, !containsVideo, false, true, {
            success: true,
            message: "Video added to playlist!"
          })
        );
        dispatch(
          setSnackbar({
            open: true,
            message: "Video added to playlist!",
            severity: "success"
          })
        );
        success && success(uuid);
      },
      (message) => {
        dispatch(
          updateListItem(uuid, containsVideo, false, false, {
            success: false,
            message: message
          })
        );
        failure && failure(uuid);
      }
    );
  };
}

/**
 * Add multiple videos to specific list
 * @param {string} uuid             [video list item]
 * @param {array} jmIds             [video jmIds]
 * @param {function} success        [success callback]
 * @param {function} failure        [failure callback]
 * @return {function} dispatch      [dispatch updated list item to reducers]
 */
export function addMultiplesVideosToList(uuid, jmIds, token) {
  const containsVideo = true;
  return (dispatch) => {
    dispatch(updateListItem(uuid, containsVideo, true));
    return addVideosToListInternal(
      uuid,
      jmIds,
      token,
      (totalVideos) => {
        dispatch(
          updateListItem(
            uuid,
            containsVideo,
            false,
            true,
            {
              success: true,
              message: "Videos added to playlist!"
            },
            totalVideos
          )
        );

        dispatch(
          setSnackbar({
            open: true,
            message: "Videos added to playlist!",
            severity: "success"
          })
        );
        dispatch(fetchPlaylist());
      },
      (message) => {
        dispatch(
          updateListItem(uuid, false, false, false, {
            success: false,
            message: message
          })
        );
        dispatch(
          setSnackbar({
            open: true,
            message: message,
            severity: "error"
          })
        );
      }
    );
  };
}

function removeVideoFromListInternal(uuid, videoJmId, token, refreshCallback, errorCallback) {
  uuid = uuid === "" ? listId : uuid;
  request
    .delete(`/api/user/licensing/lists/${uuid}/videos/${videoJmId}${forUser()}`)
    .set("Authorization", `Bearer ${token}`)
    .then((res, err) => {
      if (!res.ok) {
        errorCallback && errorCallback();
        console.log(err);
      } else {
        refreshCallback && refreshCallback();
      }
    })
    .catch(() => {
      errorCallback && errorCallback();
    });
}

export function removeVideoFromList(uuid, jmId, token, success, failure) {
  const containsVideo = true;
  return (dispatch) => {
    dispatch(updateListItem(uuid, containsVideo, true));
    return removeVideoFromListInternal(
      uuid,
      jmId,
      token,
      () => {
        dispatch(
          updateListItem(uuid, !containsVideo, false, true, {
            success: true,
            message: "Video removed from playlist!"
          })
        );
        dispatch(
          setSnackbar({
            open: true,
            message: "Video removed to playlist!",
            severity: "default"
          })
        );
        success && success(uuid);
      },
      () => {
        dispatch(
          updateListItem(uuid, containsVideo, false, false, {
            success: false,
            message: "Failed to removed video. Please try again."
          })
        );
        failure && failure(uuid);
      }
    );
  };
}

//region saved video lists
/**
 * headerVideoLists
 * @param  {object} responseData [object returned from api]
 * @return {object}              [object taken by redux and handled by the reducer]
 */
function headerVideoLists(responseData) {
  return {
    type: HEADER_SAVED_LISTS,
    data: {
      headerLists: responseData.data,
      listsCount: responseData.total,
      loadingHeaderLists: false
    }
  };
}

/**
 * Get user saved lists
 * @param {function} success        [success callback]
 * @param {function} failure        [failure callback]
 * @param {integer} max             [max number of results to return]
 * @param {integer} offset          [offset for results pagination]
 * @param {boolean} includeThumbs   [retrieve list thumbnails flag]
 * @return function calls           [call saved lists loading function and dispatch to reducers]
 */
function getVideoLists(success, failure, token, max = 1, offset = 0, includeThumbs = false) {
  request
    .get(`/api/user/licensing/lists${forUser("&")}`)
    .set("Authorization", `Bearer ${token}`)
    .query({ max: max })
    .then((res, err) => {
      if (!res.ok) {
        console.log(err);
      } else {
        success(res.body);
      }
    })
    .catch((err) => failure());
}

/**
 * Load video list for header dropdown
 * @param {function} dispatch  [dispatch to reducers]
 * @param {integer} max        [max number of results to return]
 */
function loadHeaderVideoListsInternal(dispatch, token, max = 1) {
  dispatch({ type: LOADING_HEADER_LISTS, data: { loadingHeaderLists: true } });
  getVideoLists(
    (data) => {
      dispatch(headerVideoLists(data));
    },
    () => {
      dispatch({
        type: LOADING_HEADER_LISTS,
        data: { loadingHeaderLists: false }
      });
    },
    token,
    max
  );
}

/**
 * Load saved list for header popup. Will be user for "See all" action
 * @param {integer} max         [max number of results to return]
 * @return function calls       [call saved lists loading function and dispatch to reducers]
 */
export function loadHeaderVideoLists(token, max = 5) {
  return (dispatch) => {
    return loadHeaderVideoListsInternal(dispatch, token, max);
  };
}

/**
 * Reset specific list saved state and hide list changing tip
 * @param {object} list         [video list model]
 * @return function calls       [call saved lists loading function and dispatch to reducers]
 */
export function resetVideoListSavedState(list) {
  return (dispatch) => {
    dispatch(updateListItem(list.uuid, list.containsVideo, false, false, null));
  };
}

/**
 * Create new list
 * @param {string} name         [new list name]
 * @param {string} success      [success callback]
 * @param {string} failure      [failure callback]
 * @return function calls       [call saved lists loading function and dispatch to reducers]
 */
export function createVideoList(name, success, failure) {
  return (dispatch, getState) => {
    request
      .post(`/api/user/licensing/lists${forUser()}`)
      .set("Authorization", `Bearer ${getToken(getState())}`)
      .set("Content-Type", "application/json")
      .send({
        name: name
      })
      .then((res, err) => {
        if (!res.ok) {
          console.log(err);
        } else {
          // TODO: move all logic to componentShouldUpdate or something simillar
          dispatch({
            type: UPDATE_LISTS_COUNT,
            payload: Number(getState().user.listsCount) + 1
          });

          // Always Reload main popup
          loadHeaderVideoListsInternal(dispatch, getToken(getState()), 1);

          // Call success with uuid response
          let uuid = res.text;
          try {
            uuid = JSON.parse(res.text).uuid;
          } catch (err) {}
          success && success(uuid);
        }
      })
      .catch((err) => {
        if (failure && err.response) {
          let errorProcessed = false;
          err.response.body.messages.forEach((message) => {
            if (message.startsWith("Max size of lists")) {
              failure(
                "You have reached the maximum allowed number of saved lists. Please consider deleting old lists before creating a new one."
              );
              errorProcessed = true;
            }

            if (message.startsWith("name cannot be empty")) {
              failure("List name cannot be empty.");
              errorProcessed = true;
            }

            if (message.startsWith("List name must be unique")) {
              failure("List name must be unique.");
              errorProcessed = true;
            }
          });
          !errorProcessed && failure();
        }
      });
  };
}

export function getSharedUrl(link, result, failure) {
  link = process.env.REACT_APP_FUNK_URL + link;
  return (dispatch) => {
    request
      .post(`${process.env.REACT_APP_SHORTENER_URL}/link`)
      .set("Content-Type", "application/json")
      .set("x-api-key", process.env.REACT_APP_SHORTENER_X_API_KEY)
      .send({ target: link })
      .then((res, err) => {
        if (!res.ok) {
          failure && failure();
          console.log(err);
        } else {
          result && result(res.body.response.link);
        }
      })
      .catch((err) => {
        failure && failure();
      });
  };
}

export function downloadList(uuid, listName, riffUser) {
  return (dispatch) => {
    dispatch({ type: DOWNLOAD_PLAYLIST });
    const url = `/api/public/user/licensing/lists/${uuid}/videos?offset=0`;
    request
      .get(url)
      .set("Authorization", `Bearer ${token}`)
      .then((res) => {
        let csvData = res.body.data.map((item) => {
          if (riffUser) {
            let obj = {
              JV: item.video.jmId,
              "Video Title": item.video.title,
              "Posted Date":
                item.video.originalPublishingDate === null
                  ? videoDetailDates(item.video.signedDate)
                  : videoDetailDates(item.video.originalPublishingDate),
              "Riff URL": `${process.env.REACT_APP_RIFF_URL_NOCACHE}/video/edit/${item.video.jmId}`
            };
            return obj;
          } else {
            let obj = {
              "Video Title": item.video.title,
              "Posted Date":
                item.video.originalPublishingDate === null
                  ? videoDetailDates(item.video.signedDate)
                  : videoDetailDates(item.video.originalPublishingDate),
              Link: `${process.env.REACT_APP_FUNK_URL}/licensing/view/${item.video.jmId}`
            };
            return obj;
          }
        });

        exportCSVFile(csvData, listName, true);
      });
  };
}

function exportCSVFile(JSONData, ReportTitle, ShowLabel) {
  var arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
  var CSV = "";
  CSV += ReportTitle + "\r\n\n";

  if (ShowLabel) {
    var row = "";
    for (var index in arrData[0]) {
      row += index + ",";
    }
    row = row.slice(0, -1);
    CSV += row + "\r\n";
  }

  for (var i = 0; i < arrData.length; i++) {
    row = "";
    for (index in arrData[i]) {
      row += '"' + arrData[i][index] + '",';
    }
    row.slice(0, row.length - 1);
    CSV += row + "\r\n";
  }

  if (CSV === "") {
    alert("Invalid data");
    return;
  }
  var fileName = ReportTitle.replace(/ /g, "_");
  var date = new Date();
  fileName += "-" + videoBoxDate(date);
  var uri = "data:text/csv;charset=utf-8," + escape(CSV);
  var link = document.createElement("a");
  link.href = uri;
  link.style = "visibility:hidden";
  link.download = fileName + ".csv";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function closeAddToListModal() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_LISTS_POPUP,
      data: {
        listsModalIsOpen: false
      }
    });
  };
}

export function closeAddToListMultipleVideosModal() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_LISTS_MULTIPLE_VIDEOS_POPUP,
      data: {
        listsModalMultipleVideosIsOpen: false
      }
    });
  };
}

export function addToCartRM(jmId, publishingTitle, imgUrl, variantId, price, productName, uuid) {
  return (dispatch, getState) => {
    let currentItem = {
      variantId: variantId,
      quantity: 1,
      id: jmId,
      title: publishingTitle,
      imgUrl: imgUrl,
      price: price,
      productName: productName,
      uId: jmId + variantId,
      playlistUUID: uuid
    };

    const cartData = getCurrentCartItems(getState());
    const playlistRM = cartData.filter((item) => item.playlistUUID);
    playlistRM.push(currentItem);
    dispatch(itemAdd(playlistRM));
  };
}

export function resetCart(data) {
  return (dispatch) => {
    dispatch(itemAdd(data));
  };
}

import styled from "styled-components";

const FeedbackModalWrapper = styled.div`
  .modal-title-question {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.57px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #222222;
  }

  .close-icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
    top: 20px;
  }

  .thumb-icon {
    color: #ffb400;
    height: 32px;
    width: 32px;
  }

  .thanks-feedback {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.08px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #222222;
    font-weight: 500;
    margin-top: 0;
  }

  .feedback-button {
    width: 92px;
    height: 34pxpx;
    min-width: 56px;
    padding: 8px 28px 8px 28px;
    gap: 0px;
    border-radius: 34px !important;
    opacity: 0px;
    background: black;
    color: white;
    font-weight: 500;
    margin-top: 45px;
  }
  .privacy-policy-link {
    margin-top: 10px;
    text-decoration: underline;
  }
  .privacy-policy {
    margin-top: 22px;
  }
  .privacy-policy p {
    font-size: 10px;
  }
  .privacy-policy a {
    font-size: 10px;
    color: blue;
  }
  .feedback-icon {
    margin-top: 20px;
    height: 42px;
    width: 40px;
  }
  .sparkle-icon {
    margin-top: 30px;
    height: 48px;
    width: 48px;
  }
`;

export default FeedbackModalWrapper;

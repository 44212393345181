const isPopupAllowed = () => {
  const sessionKey = "feedbackModalSession"; // Key to track if the popup has been shown in the current session
  const dailyKey = "feedbackModalDaily"; // Key to store the date when the popup was last shown
  const popupCountKey = "feedbackModalCount"; // Key to count how many times the popup has been shown in a day

  // Prevent showing the popup on the login page
  if (window.location.pathname === "/licensing/login") {
    return false;
  }

  if (
    window.location.pathname === "/licensing/login" ||
    window.location.pathname === "/licensing/register"
  ) {
    return false;
  }

  // Check if the modal has already been shown in the current session
  if (sessionStorage.getItem(sessionKey)) return false;

  // Check if the daily limit has been reached (maximum 2 times per day)
  const popupCount = parseInt(localStorage.getItem(popupCountKey) || "0", 10);
  const dailyTimestamp = localStorage.getItem(dailyKey); //

  // Check if the stored date is the same as today's date
  const now = new Date();
  const isSameDay =
    dailyTimestamp && new Date(dailyTimestamp).toDateString() === now.toDateString();

  // If it's the same day and the popup has been shown twice, do not show it again
  if (isSameDay && popupCount >= 2) {
    return false;
  }

  sessionStorage.setItem(sessionKey, "true");

  if (!isSameDay) {
    localStorage.setItem(popupCountKey, "1");
    localStorage.setItem(dailyKey, now.toISOString());
  } else {
    localStorage.setItem(popupCountKey, (popupCount + 1).toString());
  }

  return true;
};

export default isPopupAllowed;
